@font-face {
    font-family: RobotoCondensed;
    src: url(assets/roboto-condensed.light.ttf);
    font-weight:800;
}

@font-face {
    font-family: EconomicaBold;
    src: url(assets/economica-bold.ttf);
}

@font-face {
    font-family: RobotoBold;
    src: url(assets/Roboto-Bold.ttf);
}

@font-face {
    font-family: RobotoMedium;
    src: url(assets/Roboto-Medium.ttf);
}

@font-face {
    font-family: RobotoLight;
    src: url(assets/Roboto-Light.ttf);
}

* {
    margin: 0;
    padding: 0;
    }

body {
    background-color: #fff;
    font-size: 16px;
    font-family: 'RobotoCondensed';
}

@media (max-width: 991px){
    body {
        font-size: 1rem;
    }
}

.header {
    position: fixed;
    z-index: 1000;
    padding: 1rem 0;
    top: 0;
    width: 100%;
    color: #fafafa;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fafafa;
}

a {
    color: #fafafa;
}

a.darklink {
    color:#9e0000;
}

.logo {
    margin-right: 4rem;
    margin-left: 0rem;
    height: 80px;
}

.logo2 {
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 2rem;
    height: 80px;
}


@media (min-width: 992px){
    .logo2 {
        margin-top: -1rem;
        margin-left: 4rem;
    }
} 


.logo3 {
    margin-right: 0rem;
    margin-left: 0rem;
    margin-top: 2rem;
    height: 80px;
}


/* @media (min-width: 992px){
    .logo3 {
        margin-top: -1rem;
        margin-left: 4rem;
    }
}  */

/* .pageWrapper{
    display: block;
    position: relative;
} */

.contentWrapper {
    position: relative;
    top: 1rem
}

b, strong {
    font-family: 'RobotoMedium';
    /* color: #6f0000; */
}

h1, h2, h3, h4, h5 {
    font-family: 'RobotoLight';
    color: #9e0000;
}

h1, h2, h3 {
    text-transform: uppercase;
    color: #9e0000;
}

.content {
    display: inline-block; /*added*/
    text-align: left;
    line-height: 1.5;
    margin:25px 4.5%;
}

.card-body .content   {
    display: contents;
}

ul, ol {
    list-style-position: outside;
    margin: 30px;
  }

  li {
      padding-left:10px;
  }

  .responsiveImage{
      width:100%;
  }

  .bannerWrapper {
    display:flex;
    background-image: url('/karate_banner02.jpg');
    background-position: top center;
    background-size: cover;
    min-height: 450px;
    margin-bottom: 0rem;
  }

 .bannerWrapper.adult  {
    background-image: url('/karate_banner01.jpg');
  }

  .bannerWrapper.children  {
     background-image: url('/karate_banner04.jpg');
   }

   .bannerWrapper.masters  {
      background-image: url('/karate_banner05.jpg');
    }

    .bannerWrapper.contact  {
       background-image: url('/karate_banner06.jpg');
     }

     .bannerWrapper.history  {
        background-image: url('/karate_banner07.jpg');
      }

  .bannerTitle {
        display: flex;
        text-transform: uppercase;
        font-size: 15vw;
        align-self: flex-end;
        font-family: RobotoBold;
        line-height: .8;
        opacity: 0.5;
        color: white;
  }

  @media (min-width: 992px){
      .bannerTitle{
          font-size: 10vw;
      }
    }
    
  .history-bg {
      background-image: url('/karate-history-bg-opt.jpg');
      background-position: top center;
      background-size: cover;
  }

  .boldText{
      font-weight: 700;
  }

  .masterButtons{
      margin-top:30px;
      margin-bottom:20px;
  }

  .btn {
      font-size: 1rem;
      border-radius: 0rem;
      padding: 1rem;
  }
  @media (max-width: 991px){
    .btn {
        font-size: 1rem;
    }
} 


  .noBullets{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  /*//////////////////////////////*/

  .text-wrapper {
    position: absolute;
    top:30px;
    min-height: 775px;
}

.animatedText {
    min-height: 715px;
    padding: 32px 20px 20px;
    margin-top: 0px;
    background-color: black;
    display: block;
}

.animation {
    font-family: 'RobotoBold';
    font-size: 1.25rem;
    font-weight: 700;
    position: relative;
    -webkit-animation-name: animatedIntroText;
    animation-name: animatedIntroText;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    z-index: 99;
}

.fadeIn-tiger{
    -webkit-animation-delay: 31s;
    animation-delay: 31s;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
}

.animation.animation-1 {
    /* font-size: 1.25rem;
    font-weight: 700; */
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    font-family: 'RobotoMedium';
}


.animation.animation-2 {
    font-size: 1.5rem;
    font-family: 'RobotoLight';
    -webkit-animation-delay: 3s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    animation-delay: 3s;
}


.animation.animation-3 {
    font-size: 1.25rem;
    font-weight: 900;
    -webkit-animation-delay: 6s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    animation-delay: 6s;
}


.animation.animation-4 {
    font-size: 1.15rem;
    font-family: 'RobotoLight';
    -webkit-animation-delay: 10s;
    -webkit-animation-duration: 4s;
    animation-delay: 10s;
    animation-duration: 4s;
}


.animation.animation-5 {
    font-size: 1.2rem;
    -webkit-animation-delay: 14s;
    -webkit-animation-duration: 4s;
    animation-delay: 14s;
    animation-duration: 4s;
}


.animation.animation-6 {
    font-family: 'RobotoMedium';
    -webkit-animation-delay: 18s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    animation-delay: 18s;
}


.animation.animation-7 {
    -webkit-animation-delay: 22s;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    animation-delay: 22s;
}


.animation.animation-8 {
    -webkit-animation-delay: 26s;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    animation-delay: 26s;
}


.animation.animation-9 {
    font-family: 'RobotoLight';
    font-size: 1.5rem;
    -webkit-animation-delay: 28s;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    animation-delay: 28s;
}


.fade-in {
    position: relative;
    margin: 10px 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-fill-mode: both;
}


.fade-in-tiger {
    position: absolute;
    z-index: 0;
    /* margin: 10px 0; */
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 31s;
    animation-duration: 5s;
    animation-delay: 31s;
    animation-fill-mode: both;
    height: auto;
    margin-top: -30px;
}

.fade-in-tiger img {
    max-width: 1000px;
    display: block;
    z-index: 0;
    opacity: .5;
}

@media (max-width: 991px){
    .fade-in-tiger img {
        /* display: none; */
        max-width: 100%;
        /* z-index: 1;
        opacity: .5; */
    }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes animatedIntroText {
    0% {
        color: rgba(0, 0, 0, 0);
        left: -100px;
        top: 0px;
        opacity: 0;
    }

    100% {
        color: rgba(255, 255, 255, .8);
        left: 0px;
        top: 0px;
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes animatedIntroText {
    0% {
        color: rgba(0, 0, 0, 0);
        left: -100px;
        top: 0px;
        opacity: 0;
    }

    100% {
        color: rgba(255, 255, 255, .8);
        left: 0px;
        top: 0px;
        opacity: 1;
    }
}



/* Safari 4.0 - 8.0 */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* .creeds p {
    font-size: 1.5rem;
}

@media (min-width: 991px){
    .creeds p {
        font-size: 1rem;
    }
} */

.creedCard {
    background-color: #d2eaf4;
    padding: 1.5rem; 
}

@media (min-width: 991px){
    .creedCard {
        padding: 2rem;
    }
}

.img_right {
    display: none;
    background-image: url(assets/Image_136.png);
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: 0rem;
    padding-left: 10px;
     margin-left: -10px;
}

@media (min-width: 992px){
    .img_right {
        display: block;
    }
}


.img_left {
    display: none;
    background-image: url(assets/Image_138.png);
    background-repeat: no-repeat;
    background-position: left;
    background-position-x: 1rem;
    padding-right: 10px;
     margin-right: -10px;
}

@media (min-width: 992px){
    .img_left {
        display: block;
    }
}

.rules {
    display:block; /*changed from flex*/
    background-color: #fdf8eb;
}

.schedule {
    display:block; /*changed from flex*/
background-color: #fdf8eb;
    /* background-color:#fcf1e6;
    background: rgb(249, 228, 207);
    background: -moz-linear-gradient(102deg,  rgba(249, 228, 207,1) 0%,rgba(168,153,139,1) 100%);
    background: -webkit-linear-gradient(102deg,  rgba(249, 228, 207,1) 0%,rgba(168,153,139,1) 100%);
    background: linear-gradient(102deg,  rgba(249, 228, 207,1) 0%,rgba(168,153,139,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8998b', endColorstr='#f9e4cf',GradientType=1 );
     */
}

.footer {
    font-size: 1rem;
    line-height: 2rem;
    display:block; /*changed from flex*/
    color:#fafafa;
    background-color: #000;
    min-height: 15rem;
}

@media (max-width: 991px){
    .footer {
        font-size: 1rem;
        line-height: 2rem;
    }
}


.footer p {
    line-height: 2rem;
}

.footer-logo {
    text-align: left;
}

@media (max-width: 991px){
    .footer-logo {
        text-align: right;
    }
}

.contact-us {
    color:#fafafa;
    display:flex;
    background: rgb(32,1,34);
    background: -moz-linear-gradient(-45deg,  rgba(32,1,34,1) 0%, rgba(111,0,0,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(32,1,34,1) 0%,rgba(111,0,0,1) 100%);
    background: linear-gradient(135deg,  rgba(32,1,34,1) 0%,rgba(111,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#200122', endColorstr='#6f0000',GradientType=1 );
}

.contact-us h3{
    color: #fafafa;
}

.img-w-100 img {
    width:90%;
    margin-bottom: 1rem;
}

.child-title{
    width:95%;
    height: 4rem;
    padding: 1rem 0;
}

@media (min-width: 610px){
    .child-title{
        width:90%;
    }
}

/*// Bootstrap Customization //*/

.bg-dark{
    background-color: #000!important;
}

.nav-link {
    color: #495057;
    font-family: 'RobotoLight';
    /* background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; */
}

#columns-2 {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    margin: 0 20px 0 0;
}

#columns-3 {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
}

.tab-content {
    background-color: rgba(255,255,255,.5);
    padding: .125rem;
    border-radius: 0px 0px 0px 0px;
    margin: 0 !important;
}

.nav-tabs {
    border-bottom: 2px solid rgb(158,0,0);
}

ul.nav-tabs {
    margin: 0px;
}

.nav-tabs .nav-item {
    margin-bottom: 1px;
    min-width: 9rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

/* Slick Slider */

.slick-next:before, .slick-prev:before {
    font-size: 24px;
}

.container-slider {
    padding: 140px;
    background: #ccc;
    margin-bottom: 50px;
}
      
.slick-slide img {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.slick-prev:before, .slick-next:before{
    color:#9e0000;
}

@media (min-width: 576px) {
    .container {
        max-width: 95%;
    }}

    @media (min-width: 768px) {
.container {
    max-width: 95%;
}}


@media (min-width: 992px){
.container {
    max-width: 960px;
}}
    @media (min-width: 1200px){
.container {
    /* Changed from 1140px */
    max-width: 960px; 
}}

.btn.btn-dark.active.focus,
.btn.btn-dark.active:focus,
.btn.btn-dark.active:hover,
.btn.btn-dark:active.focus,
.btn.btn-dark:active:focus,
.btn.btn-dark:active:hover,
.btn.btn-dark.focus,
.btn.btn-dark:focus,
.btn.btn-dark:hover,
.btn.btn-dark.focus,
.btn.btn-dark:focus,
.btn.btn-dark:hover {
  color: #fafafa;
  background-color: #9e0000;;
  border-color: #9e0000;
  outline: none;
  box-shadow: none;
}

/*Accordion*/

.card-header {
    padding: .125rem .125rem .125rem;
    border-bottom: 0px solid #9e0000;
    /* background-color: #fdf8eb; */
    background-color: #9e0000;
}

.card {
    background-color: #fff;
    background-clip: border-box;
    border: 0px;
    border-radius: 0;
    margin-bottom: 0rem;
}

.btn-link {
    font-size: 1.5rem;
    /* color: #9e0000; */
    color: #fdf8eb;
    text-decoration: none;
    text-transform: uppercase;
}

.btn.btn-link.active.focus,
.btn.btn-link.active:focus,
.btn.btn-link.active:hover,
.btn.btn-link:active.focus,
.btn.btn-link:active:focus,
.btn.btn-link:active:hover,
.btn.btn-link.focus,
.btn.btn-link:focus,
.btn.btn-link:hover,
.btn.btn-link.focus,
.btn.btn-link:focus,
.btn.btn-link:hover {
  /* color: #9e0000; */
  color: #fdf8eb;
  outline: none;
  box-shadow: none;
  text-decoration: none;
    text-transform: uppercase;
}

ul.dojoRules{
    margin:0px 30px 30px;
}

